<template>
    <div class="users">
        <div class="push-msg-push">
            <PushMsg :users_data="users" />
        </div>
        <div class="create-new-user">
            <CreateNewUser />
        </div>
        <div class="filters">
            <div class="filter_btn">
                    <el-button @click="search_string='פרטי'" style="width:100%" type="warning">פרטיים</el-button>
            </div>
            <div class="filter_btn">
                    <el-button @click="search_string='סוכן'" style="width:100%" type="warning">סוכנים</el-button>
            </div>
            <div class="filter_btn">
                    <el-button @click="search_string='עסקי'" style="width:100%" type="warning">עסקיים</el-button>
            </div>
        </div>
        <div class="search-bar">
            <el-input placeholder="חיפוש" v-model="search_string" :input-style="input_options"/>
        </div>
        <div class="user-list">
            <template v-for="user in filtered_users" :key="user.uid">
                <div class="user-card">
                    <div class="delete-user" @click="delete_user(user)">
                        <i class="material-icons">person_add_disabled</i>
                    </div>
                    <div v-if="user?.profile?.profile_image" class="user-image">
                        <img :src="user.profile.profile_image" alt="">
                    </div>
                    <div class="user-info">
                        <p style="text-align:center; width:100%; color:var(--yellow);">{{user.profile.name}}</p>
                        <p>אימייל: {{user.email}}</p>
                        <p>סוג: {{get_user_type(user)}}</p>
                        <p style="font-size: 10px;margin: 3px 0 3px 0;">מזהה פנימי: {{user.uid}}</p>
                        <div class="options">
                        <el-button type="success" style="width:45%;" :disabled="check_if_agent(user)" @click="add_client_to_user(user)">שייך לקוח</el-button>
                        <el-button type="primary" style="width:45%;" @click="update_role(user.uid)">נהל הרשאות</el-button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { projectFirestore, projectFunctions } from '../../firebase/config';
import { computed, onMounted } from '@vue/runtime-core';
import Swal from 'sweetalert2';
import { slide_pop_error, slide_pop_successs } from '../../Methods/Msgs';
import PushMsg from '../../components/Admin/users/PushMsg.vue'
import CreateNewUser from '../../components/Admin/users/CreateNewUser.vue'
import store from '../../store';

export default {
    components:{PushMsg,CreateNewUser},
    setup(){
        
        const users = ref([]);
        const search_string = ref('');

        const filtered_users = ref(computed(() => {
            let _users = users.value
            if(!search_string.value)return _users
            else{
                return users.value.filter(user => {
                    if(user.profile.name.includes(search_string.value)) return user
                    if(user.profile.phone.includes(search_string.value)) return user
                    if(user.email.includes(search_string.value)) return user
                    if(get_user_type(user).includes(search_string.value)) return user
                })
                
            }
        }))

        const input_options = {
            'background': 'none',
            'color': 'white'
        }

        const get_users = async () => {
            let list = await projectFunctions.httpsCallable("get_user_list")();
            for (const user of list.data) {
                user.checked = false
            }
            users.value = list.data
            users.value.forEach(user=>{
                if(!user.profile?.name){
                    console.log(user);
                }
            })
        }

        const get_user_type = (user) => {
            if(user.profile.agent > 0 && user.profile.agent != 999){
                return 'סוכן'
            }
            if(user.profile.client){
                return `לקוח עסקי - ${user.profile.client}`
            }
            return 'משתמש פרטי'
        }

        const check_if_agent = (user) => {
            if(user.profile.agent > 0 && user.profile.agent != 999){
                return true
            }else{
                return false
            }
        }

        const add_client_to_user = (user) => {
            Swal.fire({
                icon: 'question',
                title: 'הכנס מספר לקוח תקני',
                input: 'text',
                confirmButtonText: 'שייך',
                cancelButtonText: 'בטל',
                showCancelButton: true,
                denyButtonText: 'בטל שיוך',
                showDenyButton: true
            })
            .then(async (res) => {
                if(res.isConfirmed && res.value && res.value.length > 3){
                    const client = await projectFirestore.collection('Clients').doc(res.value).get()
                    if(client.exists){
                        await projectFunctions.httpsCallable('update_profile')({client: res.value, uid: user.uid, type:'client', assortment: client.data().merakez,
                            price_list: client.data().price_list_id});
                        slide_pop_successs('הלקוח שוייך בהצלחה!'  + `שם הלקוח: ${client.data().name}`)
                        get_users();
                    }else{
                        slide_pop_error('מספר לקוח לא נמצא')
                    }
                }
                if(res.isDenied){
                    await projectFunctions.httpsCallable('update_profile')({client: null, uid: user.uid, type:'client'});
                    slide_pop_successs('הלקוח הוסר בהצלחה!')
                    get_users();
                }
            })
        }

        const delete_user = (user) => {
            Swal.fire({
                icon: 'warning',
                title: 'מחיקת משתמש',
                text: 'האם אתה בטוח?',
                showDenyButton: true,
                denyButtonText: 'אל תמחק',
                confirmButtonText: 'מחק משתמש'
            }).then(async res => {
                if(res.isConfirmed){
                    await projectFunctions.httpsCallable('delete_user')({user: user});
                    slide_pop_successs("המשתמש נחמק בהצלחה!")
                    let inx = users.value.findIndex(us => {
                        return us.uid == user.uid
                    })
                    if(inx != -1){
                        users.value.splice(inx, 1);
                    }
                }
            })
        }

        const update_role=async(uid)=>{
            console.log(uid);
            Swal.fire({
                icon: 'question',
                title: 'עדכן מספר הרשאה',
                input: 'text',
                confirmButtonText: 'עדכן',
                cancelButtonText: 'בטל',
                showCancelButton: true,
                denyButtonText: 'בטל עדכון',
                showDenyButton: true
            }).then(async res=>{
                if(res.isConfirmed && Number(res.value)>0){
                    const result = await projectFunctions.httpsCallable("update_user_role")({uid: uid,value:Number(res.value)})
                    if(result){
                        slide_pop_successs('בוצע עדכון הרשאה בהצלחה')
                    }else{
                        slide_pop_error('אירעה שגיאה, נסה שנית')
                    }
                }
            })
        }
        onMounted(async() => {
            store.state.loader=true
            await get_users();
            store.state.loader=false
           
        })

        return{
            filtered_users, search_string, input_options, get_user_type,
            check_if_agent, add_client_to_user, delete_user,update_role,users
        }
    }
}
</script>

<style scoped>
.users{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.loader{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #ffff;
}
.push-msg-push,.create-new-user,.filters{
    width: 375px;
    height: 50px;
    text-align: center;
}

.filters{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filters .filter_btn{
    width: 32%;
    height: 100%;
}

.search-bar{
    width: 375px;
    height: 50px;
    flex-shrink: 0;
    padding: 0 3px 0 3px;
}
.user-list{
    width: 375px;
    height: calc(100% - 200px);
    overflow-x: hidden;
    flex-shrink: 0;
    overflow-y: auto;
    padding: 0 3px 0 3px;
}
.user-card{
    position: relative;
    width: 100%;
    height: 130px;
    background: var(--secondary);
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}
.user-image{
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-image img{
    max-width: 80%;
    max-height: 100%;
    border-radius: 5px;
}
.user-info{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: white;
}
.options{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.delete-user{
    position: absolute;
    top: 10px;
    left: 10px;
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: var(--danger);
    cursor: pointer;
}
</style>
<template>
    <div class="create-new-user">
        <el-button class="new-user-btn" type="success" @click="show_new_user=!show_new_user">צור משתמש חדש</el-button>
        <el-dialog title="יצירת משתמש חדש" v-model="show_new_user" :width="get_width">
            <div v-loading="isPending" class="new-user-panel">
                <div class="field">
                    <p>שם פרטי <span class="star">*</span></p>
                    <el-input v-model.trim="values.name" placeholder="שם מלא" />
                </div>
                <div class="field">
                    <p>חברה <span class="star">*</span></p>
                    <el-input v-model.trim="values.company"  placeholder="שם חברה" />
                </div>
                <div class="field">
                    <p>טלפון <span class="star">*</span></p>
                    <el-input v-model.trim="values.phone"   placeholder="מס טלפון" />
                </div>
                <div class="field">
                    <p>דוא"ל <span class="star">*</span></p>
                    <el-input  v-model.trim="values.email"   placeholder="כתובת אימייל" />
                </div>
                <div class="field">
                    <p>סיסמה <span class="star">*</span></p>
                    <el-input v-model.trim="values.password"  placeholder="סיסמה"  show-password/>
                </div>
                <div class="field">
                    <p>סניף</p>
                    <el-select style="width:100%;" v-model="values.branche"  filterable placeholder="בחר סניף">
                            <el-option
                            v-for="brnache in branches_list"
                            :key="brnache"
                            :value="brnache"
                            >
                            </el-option>
                    </el-select>
                </div>
                <div class="field">
                    <p>תמונת פרופיל - לינק</p>
                     <el-input  placeholder="image url" />
                </div>
                <div class="send-btn">
                    <el-button @click="handle_submit" style="width:80%;" type="success">צור משתמש</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
  
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from '@vue/runtime-core'
import {slide_pop_error,slide_pop_successs} from '../../../Methods/Msgs'
import {projectFunctions,projectFirestore} from '../../../firebase/config'
export default {
    setup(){
        
        const isPending = ref(false)
        const branches_list = ref([])
        const values = ref({
            name:'',
            company:'',
            phone:'',
            email:'',
            password:'',
            branche:null,
            profile_image:'',
            uid:''
        })
        
        const show_new_user=ref(false)
        const error_msg=ref('')
        
        const handle_submit = async()=>{
            if(!validation()){
                slide_pop_error(error_msg.value)
            }else{
                isPending.value = true
                const res = await projectFunctions.httpsCallable('create_auth_user')({email:values.value.email,password:values.value.password})
                if(res.data.status){
                    values.value.uid = res.data.userId
                    await projectFunctions.httpsCallable('create_profile')(values.value)
                    slide_pop_successs(`המשתמש ${values.value.name} נוצר בהצלחה`)
                    reset_values()
                    show_new_user.value = false
                }else{
                    slide_pop_error('משתמש זה כבר קיים')
                }
                isPending.value = false
            }
        }

        const reset_values = ()=>{
            values.value ={
                name:'',
                company:'',
                phone:'',
                email:'',
                password:'',
                branche:null,
                profile_image:'',
                uid:''
            }
        }
        const validation=()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'name':
                        if(!value){
                            error_msg.value = 'עליך להזין שם פרטי'
                            return false
                        }
                        break;
                    case 'company':
                        if(!value){
                            error_msg.value = 'עליך להזין שם חברה'
                            return false
                        }
                        break;
                    case 'phone':
                        if(!value){
                            error_msg.value = 'עליך להזין מספר טלפון'
                            return false
                        }
                        break;
                    case 'email':
                        if(!value){
                            error_msg.value = 'עליך להזין כתובת אימייל'
                            return false
                        }
                        break;
                    case 'password':
                        if(!value){
                            error_msg.value = 'עליך להזין סיסמה'
                            return false
                        }
                        break;
                }
            }
            return true
        }
        const get_width = ref(computed(() => {
            if(window.innerWidth > '630'){
                return '630px'
            }else{
                return '100%'
            }
        }))

        onMounted(async()=>{
            const docs = await projectFirestore.collection('Shivuk')
            .doc('Shivuk').collection('Branches').get()
            branches_list.value = docs.docs.map(doc=>doc.data().name)
        })

        return{ 
            handle_submit,
            show_new_user, 
            get_width,
            error_msg,
            values,
            branches_list,
            isPending
        }
    }
}
</script>

<style scoped>
    .create-new-user{
        width: 100%;
        height: 100%;
    }
    .new-user-btn{
        width: 100%;
    }
    .new-user-panel{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 60vh;
        overflow: hidden;
        overflow-y: auto;
    }
    .new-user-panel::-webkit-scrollbar {
        display: none;
    }
    .field{
        width: 80%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;        
    }
    .field p{
        font-size:25px;
    }
    .send-btn{
        width: 100%;
    }
    .star{
        color: red;
    }

</style>
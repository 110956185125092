<template>
    <div class="push-msg">
        <el-button class="msg-push-btn" type="primary" @click="show_push_msg=!show_push_msg">שלח הודעת פוש</el-button>
        <el-dialog title="שליחת הודעת פוש" v-model="show_push_msg"  :width="get_width">
            <div class="push-msg-panel">
                <div class="top-bar">
                    <div class="search">
                        <el-input
                            placeholder="חיפוש"
                            v-model="search">
                            <template #prefix>
                            <i class="el-input__icon el-icon-search"></i>
                            </template>
                        </el-input>
                    </div>
                    <div class="btn" v-if="users_is_checked">
                          <el-button type="primary" @click="handle_marked_user">התראה למסומנים</el-button>
                    </div>
                </div>
                <div class="data-table">
                    <table id="users">
                        <tr>
                            <th></th>
                            <th>שם</th>
                            <th>סוג</th>
                            <th>התראות</th>
                            <th>פעולות</th>
                        </tr>
                        <template v-for="(user,index) in filter_by_search" :key="user.uid">
                            <tr>
                                <td v-if="user.profile.push_token.length>0"><input type="checkbox" :id="index" v-model="user.checked"></td>
                                <td v-else></td>
                                <td >{{user.profile.display_name}}</td>
                                <td v-if="user.profile.agent>0 && user.profile.agent<100 ">סוכן</td>
                                <td v-if="user.profile.client!=null">לקוח עסקי</td>
                                <td v-if="user.profile.agent==0 && !user.profile.client">משתמש פרטי</td>
                                <td v-if="user.profile.agent>=999">אדמין</td>
                                <td v-if="user.profile.push_token.length>0" style="color:green;">כן</td>
                                <td v-else style="color:red;">לא</td>
                                <td  v-if="user.profile.push_token.length>0"><el-button class="msg-push-btn" type="warning" @click="handle_send_tokens(user.profile.push_token)">התראה</el-button></td>
                                <td  v-else></td>
                            </tr>
                        </template>    
                    </table>
                </div> 
            </div>
        </el-dialog>
        <el-dialog title="שליחת הודעת פוש" v-model="show_msg_form"  :width="get_width">
             <div class="push-msg-panel">
                 <div class="field">
                     <p>כותרת ההתראה</p>
                     <el-input v-model="msg_data.title" placeholder="כותרת ההתראה"></el-input>
                 </div>
                 <div class="field">
                     <p>תוכן ההתראה</p>
                      <el-input
                        type="textarea"
                        :rows="2"
                        v-model="msg_data.text"
                        placeholder="תוכן ההתראה">
                      </el-input>
                 </div>
                 <div class="field">
                     <p>לינק לתמונה (אופציונלי)</p>
                     <el-input v-model="msg_data.image" placeholder="לינק"></el-input>
                 </div>
                 <div class="field">
                     <p>לינק לאתר (אופציונלי)</p>
                      <el-input v-model="msg_data.click_action" placeholder="לינק"></el-input>
                 </div>
                 <div class="field">
                     <el-button class="send-btn" type="success" @click="handle_send_msg">שליחה</el-button>
                 </div>
             </div>
        </el-dialog>

    </div>
  
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from '@vue/runtime-core'
import {slide_pop_error,slide_pop_successs} from '../../../Methods/Msgs'
import {projectFunctions} from '../../../firebase/config'
export default {
    props:['users_data'],
    setup(props){
        const search = ref('')
        const show_push_msg=ref(false)
        const show_msg_form = ref(false)
        const current_tokens=ref([])
        const msg_data=ref({
            title:'',
            text:'',
            image:'',
            click_action:''
        })
        const error_msg=ref('')
        
        const handle_marked_user=()=>{
            current_tokens.value = []
            const temp_arr = props.users_data.filter(user=>{return user.profile.push_token.length>0 && user.checked})
            for(const user of temp_arr){
                for(const token of user.profile.push_token){
                    current_tokens.value.push(token)
                }
            }
            show_msg_form.value = true

        }
        const users_is_checked=ref(computed(()=>{
            if(props.users_data.filter(user=>{return user.profile.push_token.length>0 && user.checked}).length>0)return true
            return false
        }))
        const reset_msg_data=()=>{
            msg_data.value={
                title:'',
                text:'',
                image:'',
                click_action:''
            }
        }
        const reset_check_users=()=>{
            for(const user of props.users_data){
                    user.checked = false
            }   
        }
        const handle_send_tokens=(tokens)=>{
            show_msg_form.value=true
            current_tokens.value = tokens
        }

        const handle_send_msg=async()=>{
            if(!validation()){
                 slide_pop_error(error_msg.value)
            }else{
                const obj={
                    ...msg_data.value,
                    tokens:current_tokens.value
                }
                await projectFunctions.httpsCallable('sendNotification')(obj);
                reset_msg_data()
                reset_check_users()
                show_msg_form.value=false
                slide_pop_successs('ההתראה נשלחה בהצלחה')
            }
        }

        const validation = ()=>{
            for (const [key, value] of Object.entries(msg_data.value)) {
                switch (key) {
                    case 'title':
                        if(value.length==0){
                            error_msg.value = 'נא למלא כותרת'
                            return false
                        }
                        break;
                    case 'text':
                        if(value.length==0){
                            error_msg.value = 'נא למלא תוכן להודעה'
                            return false
                        }
                        break;
                
                    default:
                        break;
                }
            }
            return true
        }
        
        const filter_by_search = ref(computed(()=>{
            if(search.value=='')return props.users_data
            return props.users_data.filter(user=>{
                if(user.profile.display_name.includes(search.value)) return user
                if(user.profile.agent>=999 && user.profile.email.includes(search.value)) return user
                if(user.profile.agent>0 && user.profile.agent<100 && search.value=='סוכן') return user
                if(user.profile.push_token.length>0 && search.value=='כן') return user
                if(user.profile.push_token.length==0 && search.value=='לא') return user
            })
        }))

        const get_width = ref(computed(() => {
            if(window.innerWidth > '630'){
                return '630px'
            }else{
                return '100%'
            }
        }))

        onMounted(()=>{
            console.log(props.users_data);
        })

        return{ 
            show_push_msg, 
            show_push_msg, 
            get_width,
            search,
            filter_by_search,
            show_msg_form,
            handle_send_tokens,
            msg_data,
            current_tokens,
            handle_send_msg,
            error_msg,
            users_is_checked,
            handle_marked_user
        }
    }
}
</script>

<style scoped>
    .push-msg{
        width: 100%;
        height: 100%;
    }
    .msg-push-btn{
        width: 100%;
    }
    .push-msg-panel{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: fit-content;
    }
    .field{
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    .field p{
        font-size:25px;
    }
    .send-btn{
        width: 100%;
    }
    .top-bar{
        width: 100%;
        height: 80px;
        border-radius: 5px;
        background:rgba(9, 94, 192, 0.104); ;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
    }
    .top-bar .search{
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
    }
     .top-bar .btn{
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
     }


    .data-table{
        width: 100%;
        height: 450px;
        overflow-y: auto;
        
        border-radius: 5px;
    }

    #users {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    }

    #users td, #users th {
        border: 1px solid #ddd;
        padding: 8px;
    }

    #users tr:nth-child(even){background-color: #f2f2f2;}

    #users tr:hover {background-color: #ddd;}

    #users th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        background-color: var(--purple);
        color: white;
    }

</style>